/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext} from "react";

import { Link } from "gatsby"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import {
  siteMetadata
} from "../../gatsby-config"

// core components
import { wrapUserConsumer } from "../components/user-context.js" 

function Profile({ user }) {
  if (typeof user === `undefined` || !user.initialLoadingComplete){
    return (                                                                                                                                                                                                  <>Loading...</>
    );
  }

  const handler = user.getEntityUpdateHandler();
  let profile = user.userData.profile[Object.keys(user.userData.profile)[0]];
  const handleSubmit = (event) => {
    event.preventDefault();
    let entityArgs = { 
      body: profile,
      identifyingPropertyString: 'profile.' + Object.keys(user.userData.profile)[0] + '.data.id',
      identifyingPropertyCondition: profile.data.id 
    }
    if (profile.data.relationships.user_picture.data && profile.data.relationships.user_picture.data.name) {
      let imageFile = new File([profile.data.relationships.user_picture.data], profile.data.relationships.user_picture.data.name);
      profile.data.relationships.user_picture.data = {
        type: 'file--file',
        meta: { 
          title: imageFile.name
        }
      }
      entityArgs.files = { user_picture: imageFile };
    } else {
      //delete user.userData.profile.data.relationships.user_picture;
    }
    handler.handle(entityArgs).then(() => {
      window.location.reload(false);
    });
  }

  const handleChange = (event) => {
    const targetVal = event.target.value, targetName = event.target.name;
    const addressFields = Object.keys(profile.data.attributes.field_address);

    if ( addressFields.includes(targetName) ) {
      profile.data.attributes.field_address[targetName] = targetVal;
    } else if (targetName == "profile_picture" && event.target.files.length) {
      profile.data.relationships.user_picture.data = event.target.files[0];
    } else {
      profile.data.attributes[targetName] = targetVal;
    }
    user.dispatch({
      type: 'updateUserData',
      payload: {
        userData: user.userData
      }
    });
  }

  function changeImageAnswer(e){
    if(e.target.files[0]){
      answerBody.data.relationships.field_answer_image = {
        data: {
          type: 'file--file',
          attributes: {
            filename: e.target.files[0].name
          }
        }
      };
      const entityArgs = {
        body: answerBody,
        identifyingPropertyString: 'answers.relationships.field_question_reference.data.0.id',
        identifyingPropertyCondition: question.drupal_id,
        newIdentifyingValue: question.drupal_id,
        files: {
          field_answer_image: e.target.files[0]
        }
      };
      handler.handle(entityArgs);
    }
  }

  if (typeof user !== `undefined` && typeof profile !== `undefined`) {
    const profileVals = profile.data;
    const profileAttrKeys = Object.keys(profileVals.attributes);
    const image = profile ? profile.included[1] : null;
    const imageUri = image ? image.attributes.uri : null;
    const imageUrl = imageUri ? imageUri.url : null;
    // This is really just to mock the schema since we don't have a schema
    for (var key of profileAttrKeys) {
      switch(key) {
        case 'field_address':
          if (profileVals.attributes.field_address === null) {
            profileVals.attributes.field_address = {
              country_code: 'US',
              given_name: null,
              family_name: null,
              address_line1: null,
              locality: null,
              postal_code: 0,    
            };
          }
          break;
        default:
          //do nothing
          break;
      }
    }
    return (
      <>
        <Container className="mt--6" fluid>
          <Row>
           <Col className="order-xl-1" xl="12">
             <Card>
               <CardHeader>
                <Row className="align-items-center">
            <Col xs="6">
              <h3 className="mb-0">Profile</h3>
            </Col>
            <Col xs="6">
              <div className="text-right">
                <Link
                  to="/contact"
                >
                  Contact Us
                </Link>
              </div>
            </Col>
          </Row>
              </CardHeader>
              <CardBody>
          <Form onSubmit={handleSubmit}>
            <h6 className="heading-small text-muted mb-4">
              User information
            </h6>
            <div className="pl-lg-4">
       <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-username"
              >
                Username
              </label>
              <Input
                onChange={ handleChange }
                defaultValue={ profileVals.attributes.name }
                name="name"
                id="input-username"
                placeholder="Username"
                type="text"
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-email"
              >
                Email address
              </label>
              <Input
                onChange={ handleChange }
                defaultValue={ profileVals.attributes.mail }
                name="mail"
                id="input-email"
                placeholder="jesse@example.com"
                type="email"
              />
          </FormGroup>
        </Col>
    </Row>
    <Row>
       <Col lg="6">
         <FormGroup>
           <label
             className="form-control-label"
             htmlFor="input-first-name"
           >
             First name
           </label>
           <Input
             onChange={ handleChange }
             defaultValue={ profileVals.attributes.field_address.given_name }
             name="given_name"
             id="input-first-name"
             placeholder="First name"
             type="text"
           />
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-last-name"
          >
            Last name
          </label>
          <Input
            onChange={ handleChange }
            defaultValue={ profileVals.attributes.field_address.family_name }
            name="family_name"
            id="input-last-name"
            placeholder="Last name"
            type="text"
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col ls="6">
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="input-profile-picture"
          >
            Profile Picture
          </label>
          {
            imageUrl ? (
                <div className="mb-2">
                  <img src={`${siteMetadata.drupalUrl}${imageUrl}`} style={{ maxHeight: '200px' }} /> 
                </div>
              ) : <></>
          }

          <Input 
            onChange={ handleChange }
            defaultValue=""
            name="profile_picture"
            id="input-profile-picture"
            type="file"
          />
        </FormGroup>
      </Col>
    </Row>
      </div>
      <hr className="my-4" />

      <h6 className="heading-small text-muted mb-4">
        Contact information
      </h6>
      <div className="pl-lg-4">
        <Row>
    <Col md="12">
      <FormGroup>
        <label
          className="form-control-label"
          htmlFor="input-address"
        >
          Address
        </label>
        <Input
          onChange={ handleChange }
          defaultValue={ profileVals.attributes.field_address.address_line1 }
          name="address_line1"
          id="input-address"
          placeholder="Home Address"
          type="text"
        />
      </FormGroup>
    </Col>
        </Row>
        <Row>
    <Col lg="4">
      <FormGroup>
        <label
          className="form-control-label"
          htmlFor="input-city"
        >
          City
        </label>
        <Input
          onChange={ handleChange }
          defaultValue={ profileVals.attributes.field_address.locality }
          name="locality"
          id="input-city"
          placeholder="City"
          type="text"
        />
      </FormGroup>
    </Col>
    <Col lg="4">
      <FormGroup>
        <label
          className="form-control-label"
          htmlFor="input-country"
        >
          Country
        </label>
        <Input
          onChange={ handleChange }
          defaultValue={ profileVals.attributes.field_address.country_code }
          name="country_code"
          id="input-country"
          placeholder="Country"
          type="text"
        />
      </FormGroup>
    </Col>
    <Col lg="4">
      <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-country"
              >
                Postal code
              </label>
              <Input
                onChange={ handleChange }
                defaultValue={ parseInt(profileVals.attributes.field_address.postal_code, 10) }
                name="postal_code"
                id="input-postal-code"
                placeholder="Postal code"
                type="number"
              />
            </FormGroup>
          </Col>
              </Row>
            </div>
            <hr className="my-4" />

            <h6 className="heading-small text-muted mb-4">About me</h6>
            <div className="pl-lg-4">
              <FormGroup>
          <label className="form-control-label">About Me</label>
          <Input
            onChange={ handleChange }
            placeholder="A few words about you ..."
            rows="4"
            type="textarea"
            defaultValue={ profileVals.attributes.field_about_me }
            name="field_about_me"
          />
        </FormGroup>
        </div>
        <Button type="submit">Submit</Button>
          </Form>
        </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
  } else {
    return (<div>Loading...</div>);
  }
}

export default wrapUserConsumer(Profile);

